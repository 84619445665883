import React from "react";
import { bool, func, noop } from "prop-types";
import classnames from "classnames";
import Calendar from "react-calendar";
import Select from "react-select";

import "./date-picker-popup.scss";

const month = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

export default class DatePickerPopup extends React.Component {
	static propTypes = {
		dateHandler: func,
		closeHandler: func,
		show: bool,
	};
	static defaultProps = {
		dateHandler: noop,
		closeHandler: noop,
		show: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			date: new Date(),
		};

		this.handlerMonthChange = this.handlerMonthChange.bind(this);
		this.handlerYearChange = this.handlerYearChange.bind(this);
	}

	componentDidMount() {
		this.props.dateHandler(this.state.date, false);
	}

	clickHandler(isReset = false) {
		if (isReset) {
			const date = new Date();
			this.setState({ date });
		}
		this.props.closeHandler(isReset);
	}

	getOptions(list) {
		return list.map((item) => {
			return { label: item, value: item };
		});
	}

	getCurrentMonth() {
		const { date } = this.state;
		return month[date.getMonth()];
	}

	getCurrentYear() {
		const { date } = this.state;
		return date.getFullYear();
	}

	selectValue = (name) => name && { label: name, value: name };

	changeSelect = (method) => async ({ value }) => {
		if (value !== this.getCurrentMonth()) {
			method(value);
		}
	};

	getYears() {
		return ["2020", "2019", "2018", "2017"];
	}

	handlerMonthChange(name) {
		const { date } = this.state;
		date.setMonth(month.indexOf(name));
		this.setState({ date }, () => {
			this.props.dateHandler(date);
		});
	}

	handlerYearChange(year) {
		const { date } = this.state;
		date.setFullYear(year);
		this.setState({ date }, () => {
			this.props.dateHandler(date);
		});
	}

	handlerCalendarChange = (date) => {
		this.setState({ date }, () => {
			this.props.dateHandler(date);
		});
	};

	render() {
		const { date } = this.state;
		const className = classnames({
			popup__box: true,
			show: this.props.show,
		});

		const customSelectStyles = {
			option: (provided, state) => ({
				...provided,
				textAlign: "left",
				background: "#0C2527",
			}),
		};
		return (
			<div className={className}>
				<div className="popup__box--content date-picker">
					<div className="date-picker__wrapper">
						<p className="date-picker__text">Отобразить направление ветра за</p>
						<div className="date-picker__params">
							<Select styles={customSelectStyles} className="date-picker__params--select" classNamePrefix="months" defaultValue={this.selectValue(this.getCurrentMonth())} options={this.getOptions(month)} onChange={this.changeSelect(this.handlerMonthChange)} />
							<Select styles={customSelectStyles} className="date-picker__params--select" classNamePrefix="years" defaultValue={this.selectValue(this.getCurrentYear())} options={this.getOptions(this.getYears())} onChange={this.changeSelect(this.handlerYearChange)} />
						</div>
						<div className="date-picker__calendar-wrapper">
							<Calendar className="date-picker__calendar" selectRange={false} showNavigation={false} showNeighboringMonth={false} onChange={this.handlerCalendarChange} value={date} activeStartDate={date} />
						</div>
						<div className="date-picker__dayslider"></div>
						<div className="date-picker__actions">
							<button className="date-picker__actions--btn" onClick={() => this.clickHandler(true)}>
								Сбросить
							</button>
							<button className="date-picker__actions--btn" onClick={() => this.clickHandler()}>
								Показать
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
