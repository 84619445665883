import React from "react";
import { any, number } from "prop-types";
import { observer } from "mobx-react";

import bus from "../../../helpers/graphMap/bus";
import mapStore from "../../../data/map.store";
import mouseHandlers from "../../../helpers/graphMap/mouseHandlers";
import projectorService from "../../../helpers/projector";
import mapService from "../../../services/map.service";
import SVGContainer from "../../../helpers/graphMap/SVGContainer";

import FactoryPoint from "./points/FactoryPoint";
import DistrictPoint from "./points/DistrictPoint";
import HatchPoint from "./points/HatchPoint";

import "./svg-container.scss";

let pointsResult = [];
@observer
class SVGScene extends React.Component {
	static propTypes = {
		canvas: any,
		scene: any,
		cityId: number,
	};

	static defaultProps = {
		canvas: undefined,
		scene: undefined,
	};

	constructor(props) {
		super(props);

		this.state = {
			inited: false,
			points: [],
			disctricts: [],
		};
		this.svgRef = React.createRef();
		this.pushPointToStore = this.pushPointToStore.bind(this);
	}

	async componentDidMount() {
		const { cityId } = this.props;
		pointsResult = await mapService.getPoints(cityId);
	}

	pushPointToStore() {
		const projector = projectorService.createProjector();
		if (pointsResult && pointsResult.length > 0) {
			for (let key in pointsResult) {
				let point = pointsResult[key];
				let pointCoord = projector(point.lon, point.lat);
				let pointRadius = projectorService.getPointRadius();
				mapStore.pushPoint(point.type, pointCoord.x, pointCoord.y, point.id, pointRadius);
			}
			mapStore.clonePointsToOriginal();
			this.pushDisctrictsNames();
		}
	}

	async pushDisctrictsNames() {
		const { cityId } = this.props;

		const disctrictPointsResult = await mapService.getDistricts(cityId);

		const projector = projectorService.createProjector();
		if (disctrictPointsResult && disctrictPointsResult.length > 0) {
			let items = [];
			for (let key in disctrictPointsResult) {
				let point = disctrictPointsResult[key];
				let pointCoord = projector(point.coords[1], point.coords[0]);
				items.push({
					x: pointCoord.x,
					y: pointCoord.y,
					name: point.name,
				});
			}
			this.setState({ disctricts: items });
		}
	}

	createSvgScene() {
		if (this.props.scene) {
			let svgConntainer = new SVGContainer(this.svgRef.current.querySelector(".scene"), this.updateSVGElements.bind(this));
			this.props.scene.appendChild(svgConntainer);
			this.setState({ inited: true });
			this.pushPointToStore();

			let handleMouseClick = this.handleMouseClick.bind(this);
			let handleMouseMove = this.handleMouseMove.bind(this);
			document.body.addEventListener("touchstart", handleMouseClick, { passive: false, capture: true });

			document.body.addEventListener("mousedown", handleMouseClick, true);
			document.body.addEventListener("mousemove", handleMouseMove, true);
		}
	}

	handleMouseMove(e) {
		const { scene } = this.props;

		if (!mouseHandlers.isSmallDevice()) {
			if (mouseHandlers.checkApplicabilityForEvent(e)) {
				const { x, y } = mouseHandlers.getCoordPipeError(e, scene.getPixelRatio());

				let s = scene.getSceneCoordinate(x, y);
				let handleUnderCursor = this.getPointIdUnderCursor(s.x, s.y, scene);
				if (handleUnderCursor) {
					mapStore.mapHoverEffect = true;
					mapStore.toggleHover(handleUnderCursor.id);
				} else {
					mapStore.resetHover();
				}
			}
		}
	}

	handleMouseClick(e) {
		const { scene } = this.props;
		var s;
		if (mouseHandlers.checkApplicabilityForEvent(e)) {
			if (mouseHandlers.isTouchEvent(e)) {
				const mainTouch = (e.changedTouches || e.touches)[0];
				const doubleClick = mouseHandlers.isDoubleClick(e);
				if (doubleClick) {
					const { x, y } = mouseHandlers.getCoordPipeError(mainTouch, scene.getPixelRatio());
					s = scene.getSceneCoordinate(x, y);
				} else {
					// should be hover.
					const { x, y } = mouseHandlers.getCoordPipeError(mainTouch, scene.getPixelRatio());
					s = scene.getSceneCoordinate(x, y);
					const handleUnderCursor = this.getPointIdUnderCursor(s.x, s.y, scene);
					if (handleUnderCursor) {
						mapStore.toggleHover(handleUnderCursor.id);
					}

					return;
				}
			} else {
				const { x, y } = mouseHandlers.getCoordPipeError(e, scene.getPixelRatio());
				s = scene.getSceneCoordinate(x, y);
			}
			let handleUnderCursor = this.getPointIdUnderCursor(s.x, s.y, scene);

			if (handleUnderCursor) {
				e.stopPropagation();
				mapStore.currentPointId = handleUnderCursor.id;
				bus.fire("select-factory");
			}
		}
		return;
	}

	getPointIdUnderCursor(sceneX, sceneY, scene) {
		let transform = scene.getTransform();
		let scale = transform.scale / scene.getPixelRatio();

		return mapStore.findPointIntersects(sceneX, sceneY, scale);
	}

	updateSVGElements(svgConntainer) {
		// let strokeWidth = 6 / svgConntainer.scale;
		// this.svgRef.current.foundPath.setAttributeNS(null, "strokeWidth", strokeWidth + "px");
		// this.scale = svgConntainer.scale / this.scene.getPixelRatio();
	}

	renderPoints() {
		const { inited } = this.state;
		if (inited && mapStore.hasPoints()) {
			let renderingItems = [];
			const { factories, hatches } = mapStore;

			for (const factory of factories) {
				renderingItems.push(<FactoryPoint key={factory.id} id={factory.id} x={factory.x} y={factory.y} r={factory.r} visible={factory.visible} />);
			}

			for (const hatch of hatches) {
				renderingItems.push(<HatchPoint key={hatch.id} id={hatch.id} x={hatch.x} y={hatch.y} r={hatch.r} visible={hatch.visible} />);
			}

			return renderingItems;
		} else {
			return null;
		}
	}

	renderDisctricts() {
		const { inited, disctricts } = this.state;
		if (inited && disctricts && disctricts.length > 0) {
			let renderingItems = disctricts.map((item, key) => {
				return <DistrictPoint key={key} name={item.name} x={item.x} y={item.y} />;
			});

			return renderingItems;
		} else {
			return null;
		}
	}

	render() {
		return (
			<svg ref={this.svgRef} className="svg-overlay absolute" shapeRendering="optimizeSpeed">
				<g className="scene">
					{this.renderPoints()}
					{this.renderDisctricts()}
				</g>
				<defs>
					<g id="test">
						<rect x="0" y="0" width="10" height="30" stroke="white" fill="white" strokeWidth="2" />
					</g>
					<g id="chimney">
						<path d="M20.135 33.877H6.003L1.292 96.791h23.946l-5.103-62.914z" fill="#fff" />
						<g fillRule="evenodd" fill="#d48181">
							<path d="M4.892 32.667h16.346L26.537 98H0l4.892-65.333zm2.22 2.42L2.583 95.58h21.355l-4.907-60.494H7.112z" />
							<path d="M6.003 33.877h14.132l1.178 14.518H4.916l1.087-14.518zM4.372 55.655l-.544 7.259H22.49l-.589-7.259H4.372zM2.741 77.433l.544-7.259h19.794l.589 7.259H2.741zm-.544 7.259l-.906 12.099h23.946l-.981-12.099H2.198z" />
						</g>
					</g>
					<g id="chimney-hover">
						<path d="M31.57 32H12.26L7 110h31l-6.43-78z" fill="#E17871" fillOpacity=".4" />
						<path d="M24.6 26H6.9L1 104h30l-6.4-78z" fill="#fff" />
						<path d="M9.03 44.94H26L25.4 43H7.2L6 54h1.82l1.21-9.06zM6.1 61.94H26L25.38 60H4.24L3 71h1.86l1.25-9.06zM5.1 80.94H28L27.38 79H3.24L2 90h1.86l1.24-9.06z" fill="#A77F7F" />
						<path fillRule="evenodd" clipRule="evenodd" d="M26.41 25H6.08L0 105h33l-6.59-80zM7.61 44.26l-.68 8.89h18.81l-.73-8.89H7.6zm18.86 17.78H6.25l-.67 8.89H27.2l-.74-8.9zm1.47 17.78H4.9l-.67 8.88h24.44l-.73-8.88z" fill="#FF5151" />
						<path d="M5.66 25h20.77l.57 2.29L9.2 30.7 1.71 105H0l5.66-80z" fill="#E7A8A8" />
					</g>
					<g id="chimney-clicked">
						<path d="M25.5 31H5L0 98.5H31L25.5 31Z" fill="#351010" />
						<path d="M22.885 34H8.721L4 96h24l-5.115-62z" fill="#dedede" />
						<g fill="#cb5454">
							<path fillRule="evenodd" d="M24.608 33H7.977L3 98h27l-5.392-65zM9.222 48.649l-.553 7.222h15.391l-.599-7.222H9.222zM24.66 63.093H8.116l-.553 7.222h17.696l-.599-7.222zm1.198 14.444H7.01l-.553 7.222h20l-.599-7.222z" />
							<path d="M28 96.015L3 97.504 30 98l-6-65 4 63.015z" />
						</g>
						<path d="M25 56l-1.032-8v7L9 55.5l16 .5zm1 15l-.968-8v7L11 70.5l15 .5zm1 14l-.97-7v6.067L11 84.533 27 85z" fill="#683a3a" />
						<path d="M30 98H3v-1.5l23.4-2.114L21 33h3.8L30 98z" fill="#5f1515" fillOpacity=".7" />
						<g fillRule="evenodd">
							<path
								d="M40.494 7.259C42.43 7.259 44 5.634 44 3.63S42.43 0 40.494 0s-3.506 1.625-3.506 3.63 1.57 3.63 3.506 3.63zM25.299 2.42c3.126 0 5.774 2.117 6.679 5.041.479-.132.983-.202 1.502-.202 3.228 0 5.844 2.708 5.844 6.049s-2.616 6.049-5.844 6.049a5.67 5.67 0 0 1-1.26-.141c-.488 2.831-2.878 4.98-5.753 4.98a5.74 5.74 0 0 1-.583-.03c-1.884 2.935-5.107 4.869-8.767 4.869-5.81 0-10.519-4.875-10.519-10.889s4.71-10.889 10.519-10.889c.52 0 1.03.039 1.53.114.931-2.879 3.557-4.954 6.652-4.954z"
								fill="#f3f3f3"
							/>
							<path
								d="M30.431 10.389l-.678-2.19c-.604-1.952-2.373-3.36-4.453-3.36-2.059 0-3.813 1.38-4.435 3.302l-.62 1.918-1.934-.292c-.388-.059-.786-.089-1.193-.089-4.519 0-8.182 3.792-8.182 8.469s3.663 8.469 8.182 8.469c2.844 0 5.351-1.5 6.82-3.789l.778-1.212 1.399.143c.115.012.233.018.353.018 1.723 0 3.159-1.288 3.452-2.985l.427-2.479 2.376.54a3.41 3.41 0 0 0 .758.085c1.936 0 3.506-1.625 3.506-3.63s-1.57-3.63-3.506-3.63a3.4 3.4 0 0 0-.903.121l-2.147.59zm10.063-5.55c.645 0 1.169-.542 1.169-1.21s-.523-1.21-1.169-1.21-1.169.542-1.169 1.21.523 1.21 1.169 1.21zm-3.456 3.669l-.101-.079c-.968-.736-2.163-1.17-3.456-1.17-.519 0-1.023.07-1.502.202-.905-2.924-3.554-5.041-6.679-5.041-3.094 0-5.721 2.075-6.652 4.954-.5-.075-1.01-.114-1.53-.114-5.81 0-10.519 4.875-10.519 10.889s4.71 10.889 10.519 10.889c3.66 0 6.883-1.934 8.767-4.869a5.74 5.74 0 0 0 .583.03c2.715 0 4.997-1.916 5.654-4.512.039-.154.072-.31.099-.468a5.63 5.63 0 0 0 .456.084 5.7 5.7 0 0 0 .804.057c3.228 0 5.844-2.708 5.844-6.049 0-1.955-.896-3.694-2.287-4.8zm1.398-1.939a3.41 3.41 0 0 0 2.057.69C42.43 7.259 44 5.634 44 3.63S42.43 0 40.494 0s-3.506 1.625-3.506 3.63a3.67 3.67 0 0 0 1.356 2.867c.031.025.062.049.093.072z"
								fill="#d06b6b"
							/>
						</g>
					</g>

					<g id="hatch">
						<ellipse cx="36.888" cy="54.692" rx="21.518" ry="21.269" fill="#cb7191" />
						<path
							fillRule="evenodd"
							d="M24.86 45.177c-1.81 2.211-3.009 4.932-3.342 7.91h3.342v-7.91zm-3.342 11.307c.333 2.978 1.532 5.698 3.342 7.91v-7.91h-3.342zm6.779 11.013a15.48 15.48 0 0 0 3.437 1.704V56.484h-3.437v11.013zm6.873 2.481a15.8 15.8 0 0 0 1.718.093c.581 0 1.154-.032 1.718-.093V56.484H35.17v13.494zm6.873-.776c1.223-.427 2.376-1.003 3.436-1.704V56.484h-3.436v12.717zm6.873-4.807c1.811-2.211 3.01-4.932 3.343-7.911h-3.343v7.911zm3.343-11.307c-.333-2.979-1.532-5.7-3.343-7.911v7.911h3.343zm-6.779-11.014c-1.06-.701-2.213-1.277-3.436-1.704v12.718h3.436V42.074zm-6.873-2.48a15.82 15.82 0 0 0-1.718-.093c-.581 0-1.154.032-1.718.093v13.494h3.436V39.593zm-6.873.776a15.48 15.48 0 0 0-3.437 1.704v11.014h3.437V40.37z"
							fill="#fff"
						/>
					</g>
					<g id="hatch-hover">
						<ellipse cx="46.59" cy="69.76" rx="22.79" ry="22.81" transform="rotate(18.64 46.59 69.76)" fill="#662D42" />
						<ellipse cx="45.34" cy="68.05" rx="22.79" ry="22.81" transform="rotate(18.64 45.34 68.05)" fill="#FB5691" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M61.32 64.63a16.31 16.31 0 00-1.36-3.84l-9.45 9.83 2.62 2.52 8.19-8.51zm-3.4-6.96a16.53 16.53 0 00-2.63-2.53L45.26 65.58l2.62 2.52 10.03-10.43zm-5.83-4.45a16.31 16.31 0 00-3.9-1.2l-8.18 8.51 2.63 2.52 9.45-9.83zm-8.82-1.33c-3.03.39-5.96 1.6-8.43 3.67L37.39 58l5.88-6.12zm-10.95 6.3a16.32 16.32 0 00-3.33 8.56l5.88-6.11-2.55-2.46zM29.3 71.66c.28 1.32.74 2.61 1.35 3.84l9.45-9.83-2.62-2.52-8.18 8.51zm3.4 6.96a16.52 16.52 0 002.62 2.52l10.03-10.43-2.62-2.52L32.7 78.63zm5.82 4.45c1.25.56 2.56.96 3.9 1.2l8.18-8.51-2.63-2.52-9.45 9.83zm8.82 1.32c3.03-.38 5.96-1.6 8.43-3.66l-2.55-2.45-5.88 6.11zm10.96-6.28a16.32 16.32 0 003.32-8.57l-5.88 6.11 2.56 2.46z"
							fill="#fff"
						/>
						<path fillRule="evenodd" clipRule="evenodd" d="M66.57 59c-2.7-4.31-7.99-9.3-13.18-11.06A22.8 22.8 0 0024.5 62.27c-1.76 5.2-.38 12.15 1.43 16.92A22.8 22.8 0 1166.57 59z" fill="#FF7BAA" />
					</g>
					<g id="hatch-clicked">
						<g stroke="#bb416d" strokeWidth="3">
							<path d="M54.861 61.462c0 11.026-9.217 20.039-20.68 20.039S13.5 72.487 13.5 61.462s9.217-20.038 20.68-20.038 20.68 9.013 20.68 20.039z" />
							<path
								d="M33.458 33.398l-.901.634.357 1.049.164.52c2.007 6.848-1.882 13.893-8.642 15.762s-13.887-2.133-15.894-8.98 1.882-13.893 8.642-15.762c.174-.048.348-.092.524-.133l1.086-.252.057-1.107c.197-3.813 2.755-7.195 6.595-8.256a9.15 9.15 0 0 1 5.351.145l1.124.374.62-.989a5.46 5.46 0 0 1 3.19-2.373c2.973-.822 6.124.936 7.013 3.97s-.836 6.14-3.809 6.961c-.154.043-.308.078-.46.107l-1.148.215-.058 1.159c-.141 2.796-1.554 5.366-3.811 6.955zM53.423 3.852c.33 1.127-.313 2.263-1.393 2.561s-2.242-.338-2.573-1.465.313-2.263 1.393-2.561 2.242.338 2.573 1.465z"
								fill="#fff"
							/>
						</g>
						<ellipse cx="37.18" cy="57.539" rx="22.18" ry="21.539" fill="#701d3b" />
						<ellipse cx="37.023" cy="55.385" rx="22.18" ry="21.539" fill="#bb416d" />
						<g fillRule="evenodd">
							<path d="M58.479 57.319c-.521-4.774-2.679-12.034-6.377-15.655C43.61 33.35 29.98 33.5 21.658 42c-3.629 3.706-5.604 11.02-6.025 15.812-.675-6.189 1.327-12.623 6.025-17.422 8.322-8.5 21.952-8.65 30.444-.336 4.789 4.689 6.921 11.068 6.377 17.265z" fill="#f06497" />
							<path
								d="M29.663 41.543c-2.616 1.285-4.867 3.298-6.409 5.913l3.146 1.403 3.263-7.316zm-7.81 9.054c-.915 2.894-.908 5.913-.116 8.718L25 52l-3.146-1.403zm1.839 13.031c.709 1.094 1.557 2.11 2.532 3.019l5.245-11.761-3.235-1.443-4.542 10.185zm5.447 5.18a16.6 16.6 0 0 0 1.579.808 16.63 16.63 0 0 0 1.656.635l5.566-12.48-3.235-1.443-5.565 12.479zm6.79 2.168a16.07 16.07 0 0 0 3.938-.133l4.543-10.186-3.235-1.443-5.245 11.761zm8.452-1.56c2.617-1.285 4.868-3.298 6.41-5.913L47.645 62.1l-3.263 7.316zm7.811-9.054c.915-2.895.909-5.915.116-8.72l-3.263 7.317 3.147 1.404zm-1.839-13.033c-.709-1.094-1.557-2.11-2.532-3.018l-5.246 11.762 3.235 1.443 4.543-10.187zm-5.447-5.179a16.6 16.6 0 0 0-1.579-.807c-.547-.244-1.1-.455-1.656-.635l-5.566 12.48 3.235 1.443 5.566-12.48zm-6.79-2.167c-1.328-.118-2.651-.07-3.938.133l-4.543 10.186 3.235 1.443 5.246-11.762z"
								fill="#fff"
							/>
						</g>
					</g>
				</defs>
			</svg>
		);
	}
}
export default SVGScene;
