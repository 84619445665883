import React from "react";
import { any } from "prop-types";

import "./zoom-map-bar.scss";
import bus from "../../../helpers/graphMap/bus";
import mapStore from "../../../data/map.store";

export default class ZoomMapBar extends React.Component {
	static propTypes = {
		canvas: any,
		scene: any,
	};

	static defaultProps = {
		canvas: undefined,
		scene: undefined,
	};

	constructor(props) {
		super(props);

		this.state = {
			zoomStepPlus: 1.2,
			zoomStepMinus: 0.8,
			level: 1,
			max: 10,
			min: 1,
		};

		this.handleMapTransform = this.handleMapTransform.bind(this);
	}

	componentDidMount() {
		bus.on("scene-created", this.handleSceneCreated, this);
	}

	handleSceneCreated() {
		const { scene, canvas } = this.props;
		if (scene) {
			scene.on("transform", this.handleMapTransform);
			scene.on("transform", this.handleMapChimneyTransform);
		}
	}

	handleMapChimneyTransform() {
		const chimneyEl = document.getElementsByClassName("point chimney")[0];
		const hatchEl = document.getElementsByClassName("point hatch")[0];
		
		if (chimneyEl) {
			const chimneyElBound = chimneyEl.getBoundingClientRect();
			mapStore.mapChimneySize = { width: chimneyElBound.width, height: chimneyElBound.height };
		}

		if (hatchEl) {
			const hatchElBound = hatchEl.getBoundingClientRect();
			mapStore.mapHatchSize = { width: hatchElBound.width, height: hatchElBound.height };
		}
	}

	handleMapTransform(e) {
		const { scene } = this.props;
		const step = (0.3 - 0.020) / 10;
		const currentScale = scene.getTransform().scale;
		// console.log(currentScale);
		let currentStep = Math.ceil(currentScale / step);
		if (currentStep >= 10) {
			currentStep = 9;
		}
		this.setState({ level: currentStep });
	}

	renderZoomLevels() {
		const { level, max, min } = this.state;
		let items = [];

		for (let index = min; index < max; index++) {
			let className = index === level ? "zoom-map-bar__levels--item active" : "zoom-map-bar__levels--item";
			items.push(<li key={index} className={className}></li>);
		}

		return <ul className="zoom-map-bar__levels">{items.reverse()}</ul>;
	}

	zoom(scaleFactor) {
		const { canvas, scene } = this.props;
		if (scene) {
			const camera = scene.getCamera();
			const canvasBounding = canvas.current.getBoundingClientRect();
			camera.zoomTo(canvasBounding.width / 2, canvasBounding.height / 2, scaleFactor);
		}
	}

	plusClickHandler() {
		const { level, max, zoomStepPlus } = this.state;
		if (level < max - 1) {
			let newValue = level + 1;
			this.setState({ level: newValue });
			this.zoom(zoomStepPlus);
		}
	}

	minusClickHandler() {
		const { level, min, zoomStepMinus } = this.state;
		if (level > min) {
			let newValue = level - 1;
			this.setState({ level: newValue });
			this.zoom(zoomStepMinus);
		}
	}

	render() {
		return (
			<div className="zoom-map-bar__wrapper">
				<div className="zoom-map-bar__container">
					<div className="zoom-map-bar__container--toggle" onClick={() => this.plusClickHandler()}>
						<span className="plus"></span>
					</div>
					{this.renderZoomLevels()}
					<div className="zoom-map-bar__container--toggle" onClick={() => this.minusClickHandler()}>
						<span className="minus"></span>
					</div>
				</div>
			</div>
		);
	}
}
