import { geoMercator, geoOrthographic, geoEqualEarth} from "d3-geo";
import mapStore from "../data/map.store";

const getPointRadius = () => {
	const { innerWidth } = window;
	if(innerWidth > 768) {
		return 14;
	}else {
		return 25;
	}
};

const createProjector = () => {
	const r = 6371393; // radius of earth in meters
	const { mapCx, mapCy } = mapStore;
	if (mapCx && mapCy) {
		var q = [0, 0];
		var projector = geoMercator()
			.center([mapCx, mapCy])
			.scale(r);
		return function(lon, lat) {
			q[0] = lon;
			q[1] = lat;

			let xyPoint = projector(q);

			return {
				x: xyPoint[0],
				y: xyPoint[1],
			};
		};
	} else {
		return () => null;
	}
};

export default { createProjector, getPointRadius};
