import React from "react";

import classnames from "classnames";

import engineStore from "../../data/engine.store";

import "./app-loader.scss";
import { observer } from "mobx-react";

@observer
export default class AppLoader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { showLoader } = engineStore;

		const className = classnames({
			"app-loader": true,
			show: showLoader,
		});

		return (
			<div className={className}>
				<div className="app-loader__container">
					<div className="pipes__container">
						<div className="pipe__wrapper">
							<div className="pipe blue">
								<span className="pipe__decorate"></span>
								<span className="pipe__decorate"></span>
								<span className="pipe__decorate"></span>
							</div>
							<div className="smoke"></div>
						</div>
						<div className="pipe__wrapper">
							<div className="pipe red">
								<span className="pipe__decorate"></span>
								<span className="pipe__decorate"></span>
								<span className="pipe__decorate"></span>
							</div>
							<div className="smoke"></div>
						</div>
						<div className="pipe__wrapper">
							<div className="pipe yellow">
								<span className="pipe__decorate"></span>
								<span className="pipe__decorate"></span>
								<span className="pipe__decorate"></span>
							</div>
							<div className="smoke"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
