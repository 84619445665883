import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import IndexPage from "../index-page/IndexPage";
import AboutUsPage from "../about-us-page/AboutUsPage";
import NotFoundCityPage from "../not-found-city-page/NotFoundCityPage";

import AppLoader from "../../components/app-loader/AppLoader";

import "../../stylesheets/index.scss";
export default class Index extends React.Component {
	renderContent() {
		return (
			<Switch>
				<Route exact path="/" component={IndexPage} />
				{/* <Route exact path="/:city?" component={IndexPage} /> */}
				<Route exact path="/about-us" component={AboutUsPage} />

				<Redirect to="/" />
			</Switch>
		);
	}

	render() {
		return (
			<BrowserRouter>
				{this.renderContent()}
				<AppLoader />
			</BrowserRouter>
		);
	}
}
