import React from "react";
import { bool } from "prop-types";
import { debounce, findIndex, remove } from "lodash";
import classnames from "classnames";

import AsyncSelect from "react-select/async";

import "./search-bar.scss";
import entityService from "../../../services/entity.service";
import mapStore from "../../../data/map.store";
import engineStore from "../../../data/engine.store";

const debouncedLoadOptions = debounce((searchName, callback) => {
	entityService
		.search(searchName)
		.then((result) => callback(result))
		.catch((error) => callback([]));
}, 500);

export default class SearchBar extends React.Component {
	static propTypes = {
		show: bool,
	};

	static defaultProps = {
		show: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			value: "",
			placeholder: "Найти предприятие или вещество",
			noOptionsMessage: "Нет совпадений",
			loadingMessage: "Загружаем...",

			searchWords: [],
		};
	}

	onClickSearchWord(id) {
		const { searchWords } = this.state;
		const updatedWord = remove(searchWords, function(item) {
			return item.id != id;
		});
		this.setState({ searchWords: updatedWord }, () => {
			if (updatedWord.length > 0) {
				entityService.showBySubstances(updatedWord);
			} else {
				mapStore.showAllPoints();
			}
		});
	}

	renderSearchWords() {
		const { searchWords } = this.state;
		if (searchWords.length > 0) {
			let words = searchWords.map((item, key) => {
				return (
					<li key={key} className="searchwords__list--item" onClick={() => this.onClickSearchWord(item.id)}>
						{this.trimName(item.name, 15)}
						<span className={`searchwords__list--icon ${item.group}`}></span>
					</li>
				);
			});
			return <ul className="searchwords__list">{words}</ul>;
		} else {
			return null;
		}
	}

	trimName(name, maxLenght = 10) {
		if (name.length >= maxLenght) {
			return `${name.substr(0, maxLenght)}...`;
		} else {
			return name;
		}
	}

	handleInputChange(event) {
		if (event && event.value !== null) {
			if (event.groupName === "factories") {
				this.selectFactory(event);
			} else {
				this.selectSubstances(event.value, event.label, event.groupName);
			}
		} else {
			// if search by search words don't show all.
			if (this.state.searchWords.length === 0) {
				mapStore.showAllPoints();
			}
		}
	}

	async selectFactory({ value, label }) {
		const ids = value.split(",");
		const hatchesResult = await entityService.getRelatedHatchesByCompany(label);
		if (ids) {
			if (hatchesResult && hatchesResult.length > 0) {
				mapStore.showHatchesOnMap = true;
				mapStore.findPointAndSelect(ids.concat(hatchesResult));
			} else {
				mapStore.showHatchesOnMap = false;
				mapStore.findPointAndSelect(ids);
			}
		}
	}

	selectSubstances(id, name, group) {
		const { searchWords } = this.state;
		if (findIndex(searchWords, ["id", id]) === -1) {
			searchWords.push({
				id,
				name,
				group,
			});
			entityService.showBySubstances(searchWords);
			this.setState({ searchWords });
		}
	}

	handleMenyToggle(isOpen) {
		engineStore.toggleSearchBarMenu(isOpen);
	}

	render() {
		const className = classnames({
			"search-bar": true,
			disable: this.props.show,
		});

		const customStyles = {
			control: (base, state) => ({
				...base,
				boxShadow: "none",
			}),
			menu: (provided) => ({ ...provided, zIndex: 9999 }),
		};

		return (
			<div className={className}>
				<div className="search-bar__container">
					<span className="search-bar__search-btn"></span>
					<AsyncSelect
						styles={customStyles}
						className="search-bar__wrapper"
						classNamePrefix="search-bar__input"
						isMulti={false}
						isClearable={true}
						placeholder={this.state.placeholder}
						noOptionsMessage={() => this.state.noOptionsMessage}
						loadingMessage={() => this.state.loadingMessage}
						loadOptions={debouncedLoadOptions}
						onChange={(e) => this.handleInputChange(e)}
						onMenuOpen={() => this.handleMenyToggle(true)}
						onMenuClose={() => this.handleMenyToggle(false)}
					/>
				</div>

				<div className="search-bar__searchwords">{this.renderSearchWords()}</div>
			</div>
		);
	}
}
