import React from "react";

import "./logo.scss";

export default class Logo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			color: "#fff"
		};
	}

	render() {
		return (
			<div className="logo__container">
				<svg viewBox="0 0 101 38" fill={this.state.color} xmlns="http://www.w3.org/2000/svg">
					<path d="M5.40223 18.0859H10.3929V4.3923H15.7951V0H0V4.3923H5.40223V18.0859Z" fill={this.state.color} />
					<path
						d="M17.9857 18.0859H22.9764V12.9185H25.4459C29.9221 12.9185 33.1891 10.6707 33.1891 6.40759C33.1891 2.35117 30.2565 0 25.7032 0H17.9857V18.0859ZM22.9764 8.99129V4.21144H25.3173C27.1181 4.21144 28.1985 5.03822 28.1985 6.58845C28.1985 8.06116 27.1181 8.99129 25.343 8.99129H22.9764Z"
						fill="white"
					/>
					<path
						d="M39.5137 18.3443C42.2919 18.3443 43.9641 17.0266 45.6105 13.797L52.6334 0H47.2054L43.3467 8.50039L39.2564 0H33.6998L40.7484 13.3577C40.2082 13.9778 39.6166 14.1329 39.1792 14.1329C38.639 14.1329 37.8415 13.9778 37.147 13.5128L35.1919 17.13C36.4009 17.8534 37.7901 18.3443 39.5137 18.3443Z"
						fill="white"
					/>
					<path
						d="M54.2367 18.0859H62.9575C67.2021 18.0859 69.9289 15.9156 69.9289 12.1692C69.9289 8.26785 66.8677 6.35591 62.7002 6.35591H59.2274V4.26311H68.7199V0H54.2367V18.0859ZM59.2274 13.8745V10.1281H62.5973C64.0637 10.1281 64.9383 10.8516 64.9383 11.9625C64.9383 13.2027 64.0637 13.8745 62.5716 13.8745H59.2274Z"
						fill="white"
					/>
					<path
						d="M72.2492 18.0859H80.2239C84.6229 18.0859 87.4526 15.7348 87.4526 11.8334C87.4526 7.75111 84.2885 5.68415 79.9667 5.68415H77.2398V0H72.2492V18.0859ZM77.2398 13.8745V9.61138H79.8638C81.4844 9.61138 82.462 10.4382 82.462 11.7042C82.462 13.1252 81.4844 13.8745 79.838 13.8745H77.2398ZM89.5621 18.0859H94.5527V0H89.5621V18.0859Z"
						fill="white"
					/>
					<path
						d="M10.0584 38C15.615 38 19.7825 33.7886 19.7825 28.5953C19.7825 23.3504 15.6665 19.1906 10.1099 19.1906C4.55331 19.1906 0.385874 23.4021 0.385874 28.5953C0.385874 33.8402 4.50186 38 10.0584 38ZM10.1099 33.4785C7.38305 33.4785 5.50513 31.2049 5.50513 28.5953C5.50513 25.9341 7.3316 23.7121 10.0584 23.7121C12.811 23.7121 14.6889 25.9858 14.6889 28.5953C14.6889 31.2565 12.8625 33.4785 10.1099 33.4785Z"
						fill="white"
					/>
					<path d="M22.3821 37.6383H27.2698V27.3035L31.8488 34.3311H31.9517L36.5565 27.2518V37.6383H41.5214V19.5524H36.2478L31.9517 26.5542L27.6557 19.5524H22.3821V37.6383Z" fill="white" />
					<path
						d="M53.3848 38C57.3722 38 59.6617 36.2689 61.3595 33.9177L57.6037 31.2307C56.5232 32.5484 55.4171 33.4268 53.5392 33.4268C51.0181 33.4268 49.2431 31.3082 49.2431 28.5953C49.2431 25.9083 51.0181 23.7638 53.5392 23.7638C55.2627 23.7638 56.4718 24.5647 57.5008 25.8566L61.1537 23.0145C59.5588 20.7925 57.2693 19.1906 53.5906 19.1906C48.1369 19.1906 44.0981 23.3246 44.0981 28.5953C44.0981 34.0469 48.2398 38 53.3848 38Z"
						fill="white"
					/>
					<path d="M63.5067 37.6383H68.4974V32.9618L70.3239 30.9207L74.8514 37.6383H80.8453L73.7967 27.3293L80.6138 19.5524H74.6971L68.4974 26.8901V19.5524H63.5067V37.6383Z" fill="white" />
					<path d="M80.8574 37.6383H86.1053L87.3915 34.4087H94.3372L95.6492 37.6383H101L93.334 19.4232H88.5234L80.8574 37.6383ZM88.8578 30.5073L90.8901 25.3657L92.8966 30.5073H88.8578Z" fill="white" />
				</svg>
			</div>
		);
	}
}
