import { extendObservable, computed, action, toJS } from "mobx";

class EngineStore {
	constructor() {
		extendObservable(this, {
			showAboutUs: false,
			showLoader: true,
			showSidebar: false,
			showDatePicker: false,
			showSearchBarMenu: false,
			myContent: [],
		});
	}

	@action
	setContent(value) {
		this.myContent = value;
	}

	@action
	toggleAboutUs(state) {
		if(state) {
			// should be close sidebar.
			this.showSidebar = false;
			this.showDatePicker = false;
		}
		this.showAboutUs = state;
	}

	@action
	toggleLoader(state) {
		this.showLoader = state;
	}

	@action
	toggleSidebar(state) {
		this.showSidebar = state;
	}

	@action
	toggleDatePicker(state) {
		this.showDatePicker = state;
	}

	@action
	toggleSearchBarMenu(state) {
		this.showSearchBarMenu = state;
	}

	@computed
	get content() {
		return toJS(this.myContent);
	}
}

export default new EngineStore();
