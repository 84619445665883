import httpService from "./http";

const getById = async (id) => {
	try {
		const data = {
			action: `factory/${id}`,
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

const search = async (name, cityId) => {
	try {
		const data = {
			action: `factory/search`,
			data: {
				name,
				cityId,
			},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

const searchBySubstances = async (words, cityId) => {
	try {
		const data = {
			action: `factory/search/substances`,
			data: {
				words,
				cityId,
			},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

export default { getById, search, searchBySubstances };
