import axios from "axios";
import engineStore from "../../data/engine.store";

const apiUrl = process.env.REACT_APP_API_URI ? process.env.REACT_APP_API_URI : "http://localhost:8085/api";
const basicAuth = {
	username: process.env.REACT_APP_API_AUTH_USERNAME ? process.env.REACT_APP_API_AUTH_USERNAME : "",
	password: process.env.REACT_APP_API_AUTH_PASSWORD ? process.env.REACT_APP_API_AUTH_PASSWORD : "",
};

const apiAxios = axios.create({
	baseURL: apiUrl,
	auth: basicAuth,
});


export default {
	delete: apiAxios.delete,
	get: apiAxios.get,
	post: apiAxios.post,
	put: apiAxios.put,
	apiUrl,
};
