import React from "react";
import { object } from "prop-types";
import { observer } from "mobx-react";

import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import NotFoundCity from "../../components/not-found-city/NotFoundCity";

import AboutUsPage from "../about-us-page/AboutUsPage";

import engineStore from "../../data/engine.store";

import "./index-page.scss";
import cityStore from "../../data/city.store";
import cityService from "../../services/city.service";
import FactoryInfoSidebar from "../../components/factory-info-sidebar/FactoryInfoSidebar";
@observer
export default class IndexPage extends React.Component {
	static propTypes = {
		match: object,
	};

	constructor(props) {
		super(props);

		this.state = {
			city: null,
			notFound: false,
		};
	}

	componentDidMount() {
		// const { city } = this.props.match.params;
		const city = "omsk"; //!
		const { currentCity } = cityStore;
		if (city) {
			cityService.initCity(city);
		} else {
			if (currentCity) {
				this.seState({ city: city });
			}
		}
	}

	renderMap() {
		// const { notFound, city } = this.state;
		const { cityId } = cityStore;
		if (cityId) {
			const isCityAvailable = true; // ask api
			if (isCityAvailable) {
				return <Map cityId={cityId}></Map>;
			}
		}
		return <NotFoundCity />;
	}

	renderAboutUs() {
		const { showAboutUs } = engineStore;
		if (showAboutUs) {
			return <AboutUsPage />;
		} else {
			return null;
		}
	}

	render() {
		return (
			<div className="index-page__container">
				<Header></Header>
				{this.renderMap()}
				<FactoryInfoSidebar />
				{this.renderAboutUs()}
			</div>
		);
	}
}
