import httpService from "./http";

const search = async (name, cityId) => {
	try {
		const data = {
			action: `search`,
			data: {
				name,
				cityId,
			},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

const bySubstances = async (words, cityId) => {
	try {
		const data = {
			action: `search/substances`,
			data: {
				words,
				cityId,
			},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

export default { search, bySubstances };
