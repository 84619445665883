import cityStore from "../data/city.store";
import mapStore from "../data/map.store";
import apiFactory from "./api/factory.api";
import apiHatch from "./api/hatch.api";
import searchApi from "./api/search.api";
import apiSearch from "./api/search.api";

const get = async ({ type, id }) => {
	try {
		let result = null;
		if (type === "factory") {
			result = await apiFactory.getById(id);
		} else if (type === "hatch") {
			result = await apiHatch.getById(id);
		}
		if (result) {
			return result;
		}
	} catch (error) {
		return error;
	}
};

const search = async (name) => {
	try {
		const { cityId } = cityStore;
		const responseResult = await apiSearch.search(name, cityId);
		let result = [];
		for (let groupName in responseResult) {
			let items = responseResult[groupName];
			let label = "";
			if (groupName === "substancesHatches") {
				label = "Вещества из канализации";
			} else if (groupName === "substancesFactories") {
				label = "Вещества";
			} else {
				label = "Фабрики";
			}
			result.push({
				label,
				options: items.map((resItem) => ({ value: resItem.id, label: resItem.name, groupName: groupName })),
			});
		}
		return result;
	} catch (error) {
		return error;
	}
};

const showBySubstances = async (words) => {
	const { cityId } = cityStore;
	try {
		let search = words.map((item) => {
			return `${item.group}#${item.name}`;
		});
		const result = await searchApi.bySubstances(search, cityId);

		if (result) {
			mapStore.showOnlyPointByIds(result);
		}
	} catch (error) {
		return error;
	}
};

const getRelatedHatces = async (id) => {
	try {
		const result = await apiHatch.getRelated(id);
		if (result && Array.isArray(result)) {
			let transformed = result.map((item) => {
				return `hatch-${item}`;
			});
			return transformed;
		} else {
			return null;
		}
	} catch (error) {
		return error;
	}
};

const getRelatedHatchesByCompany = async (companyName) => {
	try {
		const { cityId } = cityStore;
		const result = await apiHatch.getByCompany(companyName, cityId);

		return result;
	} catch (error) {
		return [];
	}
};

export default { get, search, showBySubstances, getRelatedHatces, getRelatedHatchesByCompany };
