import api from "./api/map.api";
import { isEmpty } from "lodash";
import mapStore from "../data/map.store";
import createGraph from "ngraph.graph";
import BBox from "../helpers/graphMap/BBox";
import asyncFor from "rafor";
import bus from "../helpers/graphMap/bus";
import engineStore from "../data/engine.store";

const createMap = async (id) => {
	let graph = createGraph();
	let graphBBox = new BBox();
	engineStore.toggleLoader(true); // show loader.

	try {
		const getMapResult = await api.getMapInfo(id);

		if (!isEmpty(getMapResult) && !isEmpty(getMapResult.files)) {
			mapStore.bboxCoords = getMapResult.bbox;

			const coordPoints = await api.getMapFileBin(getMapResult.files.coords);
			const roadsPoints = await api.getMapFileBin(getMapResult.files.roads);
			// const coordPoints = await api.getMapFileBin("http://localhost:3000/6dgAaqeph6IoDC8luJThLI1juy5sE8rkkfay9CTk.bin");
			// const roadsPoints = await api.getMapFileBin("http://localhost:3000/ocrMAvKbxPvaBr3MLWA3wEbjsuJVNFALcu6WLw8U.bin");

			initNodes(coordPoints).then(() => {
				setLinks(roadsPoints).then(() => {
					mapStore.graph = graph;
					mapStore.graphBBox = graphBBox;
					bus.fire("graph-loaded");
				});
			});
		} else {
			throw new Error("Files urls not found.");
		}
	} catch (error) {
		bus.fire("graph-loaded-failed");
		return false;
	}

	function initNodes(points) {
		return new Promise((resolve) => {
			asyncFor(
				points,
				addPointToGraph,
				() => {
					resolve();
				},
				{ step: 2 }
			);
		});
	}

	function addPointToGraph(x, i, points) {
		let nodeId = Math.floor(i / 2);
		let y = points[i + 1];
		graph.addNode(nodeId, { x, y });
		graphBBox.addPoint(x, y);
	}

	function setLinks(links) {
		return new Promise((resolve) => {
			asyncFor(
				links,
				addLinkToGraph,
				() => {
					// console.log(graph.getLinksCount() + " edges; " + graph.getNodesCount() + " nodes.");
					resolve();
				},
				{ step: 2 }
			);
		});
	}

	function addLinkToGraph(fromShifted, i, links) {
		let fromId = fromShifted - 1;
		let toId = links[i + 1] - 1;

		graph.addLink(fromId, toId);
	}
};

const getPoints = async (cityId) => {
	const getMapResult = await api.getMapPoints(cityId);

	if (getMapResult) {
		return getMapResult;
	} else {
		return null;
	}
};

const getDistricts = async (cityId) => {
	const getMapResult = await api.getMapMeta(cityId, "district");
	if (getMapResult) {
		let result = getMapResult.map((item) => {
			const { meta_value } = item;
			return {
				name: meta_value.name,
				coords: meta_value.coords.split(";"),
			};
		});
		return result;
	} else {
		return null;
	}
};

export default { createMap, getPoints, getDistricts };
