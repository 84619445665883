import React from "react";
import classnames from "classnames";

import Logo from "./logo/Logo";
import SearchBar from "./search-bar/SearchBar";
import MenuBurger from "./menu-burger/MenuBurger";

import EngineStore from "../../data/engine.store.js";

import "./header.scss";


export default class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showAboutUs: false,
		};
	}

	showAboutUs = () => {
		const currentState = !this.state.showAboutUs;
		this.setState({ showAboutUs: !this.state.showAboutUs }, () => {
			EngineStore.toggleAboutUs(currentState);
		});
	};

	render() {
		const className = classnames({
			"header": true,
			"header-red": this.state.showAboutUs,
		});
		return (
			<header className={className}>
				<div className="header__container container">
					<Logo></Logo>

					<SearchBar show={this.state.showAboutUs}></SearchBar>

					<MenuBurger onClickHandler={this.showAboutUs}></MenuBurger>
				</div>
			</header>
		);
	}
}
