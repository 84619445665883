import React from "react";
import { func } from "prop-types";
import classnames from "classnames";

import "./menu-burger.scss";

export default class MenuBurger extends React.Component {
	static propTypes = {
		onClickHandler: func,
	};

	static defaultProps = {
		onClickHandler: undefined,
	};

	constructor(props) {
		super(props);

		this.state = {
			active: false,
		};
	}

	onClick = () => {
		this.setState({ active: !this.state.active }, () => {
			this.props.onClickHandler();
		});
	};

	render() {
		const className = classnames({
			"menu-burger__icon": true,
			active: this.state.active,
		});

		return (
			<div className="menu-burger__container">
				<div className={className} onClick={this.onClick}>
					<span className="menu-burger__icon--line"></span>
					<span className="menu-burger__icon--line"></span>
					<span className="menu-burger__icon--line"></span>
				</div>
			</div>
		);
	}
}
