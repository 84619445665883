import React from "react";
import { string, number } from "prop-types";

export default class DistrictPoint extends React.Component {
	static propTypes = {
		name: string,
		x: number,
		y: number,
	};

	static defaultProps = {
		name: "",
		x: null,
		y: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			transform: "matrix(0, 0, 0, 0, 0, 0)",
		};
	}

	componentDidMount() {
		const { x, y } = this.props;

		if (x !== null && y !== null) {
			this.setToScene();
		}
	}

	setToScene() {
		let mainScale = 20;
		let scale = mainScale / 1;
		let dx = this.props.x; // error factor?
		let dy = this.props.y; // error factor?
		// let dx = this.state.x * 1.156; // error factor?
		// let dy = this.state.y * 1.156; // error factor?
		this.setState({ transform: `matrix(${scale}, 0, 0, ${scale}, ${dx}, ${dy})` });
	}

	render() {
		const { name } = this.props;
		return (
			<text transform={this.state.transform} fontSize="85" fontWeight="bold" fill="#fff" fillOpacity="0.5" fontFamily="Gotham Pro, Helvetica, sans-serif">
				{name}
			</text>
		);
	}
}
