import React from "react";

import classnames from "classnames";

import "./app-mobile-promt.scss";
import { observer } from "mobx-react";
import mouseHandlers from "../../helpers/graphMap/mouseHandlers";

@observer
export default class AppMobilePromt extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
		};
	}

	componentDidMount() {
		if (mouseHandlers.isSmallDevice()) {
			this.setState({ show: true });
		}
	}

	handleClick() {
		if (this.state.show) {
			this.setState({ show: false });
		}
	}

	render() {
		const { show } = this.state;

		const className = classnames({
			"app-mobile-promt": true,
			show: show,
		});

		return (
			<div className={className} onClick={() => this.handleClick()}>
				<div className="app-mobile-promt__wrapper">
					<div className="app-mobile-promt__container">
						<div className="app-mobile-promt__container--icon"></div>
						<p className="app-mobile-promt__container--notice">Нажмите дважды, чтобы увидеть подробности.</p>
					</div>
				</div>
			</div>
		);
	}
}
