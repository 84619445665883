import React from "react";
import { find } from "lodash";
import { observer } from "mobx-react";
import engineStore from "../../data/engine.store";

import SimpleContentParts from "./content-parts/SimpleContentParts";
import contentService from "../../services/api/content.api";

import "./about-us-page.scss";

@observer
export default class AboutUsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentNavKey: "aboutus",
			navItemsList: [
				{ label: "О проекте", key: "aboutus" },
				{ label: "Как это работает", key: "howitworks" },
				{ label: "ПДК", key: "pdk" },
			],
		};
	}

	async componentDidMount() {
		const result = await contentService.getContents();
		if (result && result.length > 0) {
			engineStore.setContent(result);
		}
	}

	onClickNavHandler(key) {
		this.setState({ currentNavKey: key });
	}

	renderContent() {
		const { currentNavKey } = this.state;
		const { content } = engineStore;
		if (currentNavKey && content) {
			const currentContent = find(content, ["slug", currentNavKey]);
			if (currentContent) {
				switch (currentNavKey) {
					case "howitworks":
						return <SimpleContentParts title={currentContent.title} content={currentContent.content} />;
					case "aboutus":
						return <SimpleContentParts title={currentContent.title} content={currentContent.content} />;
					case "pdk":
						return <SimpleContentParts title={currentContent.title} content={currentContent.content} />;
					default:
						return null;
				}
			}
		}
		return null;
	}

	renderNav() {
		let items = this.state.navItemsList.map((item) => {
			const className = item.key == this.state.currentNavKey ? "active" : null;
			return (
				<li className={className} key={item.key} onClick={() => this.onClickNavHandler(item.key)}>
					{item.label}
				</li>
			);
		});
		return <ul className="aboutus-page__nav--list">{items}</ul>;
	}

	render() {
		return (
			<div className="aboutus-page__section">
				<div className="aboutus-page__container container">
					<div className="aboutus-page__content">{this.renderContent()}</div>
				</div>
				<div className="aboutus-page__nav">{this.renderNav()}</div>
			</div>
		);
	}
}
