import React from "react";
import { string, number, bool } from "prop-types";
import classnames from "classnames";

import "./point.scss";
import mapStore from "../../../../data/map.store";
import { observer } from "mobx-react";

@observer
export default class FactoryPoint extends React.Component {
	static propTypes = {
		id: string,
		x: number,
		y: number,
		r: number,
		visible: bool,
	};

	static defaultProps = {
		id: null,
		x: null,
		y: null,
		r: null,
		visible: true,
	};

	constructor(props) {
		super(props);

		this.state = {
			transform: "matrix(0, 0, 0, 0, 0, 0)",
		};
	}

	componentDidMount() {
		const { x, y } = this.props;

		if (x !== null && y !== null) {
			this.setToScene();
		}
	}

	setToScene() {
		let mainScale = 10;
		let scale = mainScale / 1;
		let dx = this.props.x; // error factor
		let dy = this.props.y; // error factor?
		this.setState({ transform: `matrix(${scale}, 0, 0, ${scale}, ${dx}, ${dy})` });
	}

	getHref(id) {
		let href = "#chimney";
		const { currentPointId, currentHoverPointId } = mapStore;

		if (id === currentPointId) {
			href = "#chimney-clicked";
		} else if (id === currentHoverPointId) {
			href = "#chimney-hover";
		}

		return href;
	}

	render() {
		const { id, visible } = this.props;
		const className = classnames({
			point: true,
			chimney: true,
			hidden: !visible,
		});

		return <use id={id} transform={this.state.transform} className={className} href={this.getHref(id)} />;
	}
}
