import React from "react";
import { bool } from "prop-types";
import classnames from "classnames";

import "./component-loader.scss";

export default class ComponentLoader extends React.Component {
	static propTypes = {
		show: bool,
	};

	static defaultProps = {
		show: true,
	};

	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { show } = this.props;
		const className = classnames({
			"component-loader": true,
			show: show,
		});

		return (
			<div className={className}>
				<div className="app-loader__container">
					<div className="lds-ripple">
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		);
	}
}
