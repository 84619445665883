import React from "react";
import { number } from "prop-types";
import { observer } from "mobx-react";
import classnames from "classnames";
import { isWebGLEnabled, scene, WireCollection } from "w-gl";

import bus from "../../helpers/graphMap/bus";
import mapStore from "../../data/map.store";
import mapService from "../../services/map.service";

import ZoomMapBar from "./zoom-map-bar/ZoomMapBar";
import SVGScene from "./svg-scene/SVGScene";
import engineStore from "../../data/engine.store";
import Weather from "../weather/Weather";
import AppMobilePromt from "../app-mobile-promt/AppMobilePromt";

import "./map.scss";
import MapControl from "./map-control/MapControl";

const panzoomOptions = {
	zoomSpeed: 0.015,
	maxZoom: 0.3,
	minZoom: 0.020,
	initialZoom: 0.025,
	zoomDoubleClickSpeed: 1, // disable double click
	// smoothScroll: false,
	onDoubleClick: function(e) {
		return false; // tells the library to not preventDefault, and not stop propagation
	},
};

@observer
export default class Map extends React.Component {
	static propTypes = {
		cityId: number,
	};

	constructor(props) {
		super(props);

		this.state = {
			isWebGL: true,
			scene: null,
		};

		this.canvasRef = React.createRef();
		this.svgSceneRef = React.createRef();
	}

	async componentDidMount() {
		const { cityId } = this.props;

		this.initWebGl();

		await mapService.createMap(cityId);
	}

	initWebGl() {
		if (isWebGLEnabled(this.canvasRef.current)) {
			bus.on("graph-loaded", this.createScene, this);
			bus.on("graph-loaded-failed", this.failed, this);
		} else {
			this.setState({ isWebGL: false });
		}
	}

	failed() {
		console.log("failed load binary files"); //?
		this.ensurePreviousSceneDestroyed();
	}

	createScene() {
		this.ensurePreviousSceneDestroyed();
		let canvas = this.canvasRef.current;
		this.setState({ scene: scene(canvas, { panzoom: panzoomOptions }) }, () => {
			this.state.scene.setPixelRatio(2);
			this.state.scene.setClearColor(15 / 255, 43 / 255, 46 / 255, 1);
			this.setViewBox();
			this.setGraphLines();
			this.svgSceneRef.current.createSvgScene();

			setTimeout(() => {
				this.moveSceneToCenter();
			}, 500);

			this.finishFireCreateScene();
			// this.unsubscribeMoveEvents = function () {
			// 	document.body.removeEventListener('mousedown', boundMouseDown, true);
			// 	document.body.removeEventListener('touchstart', boundMouseDown, true);
			// }
		});
	}

	finishFireCreateScene() {
		setTimeout(() => {
			engineStore.toggleLoader(false);
		}, 1000);
		bus.fire("scene-created");
	}

	setViewBox() {
		const { bboxSceneSize } = mapStore;
		if (bboxSceneSize) {
			this.state.scene.setViewBox({
				left: -bboxSceneSize,
				top: -bboxSceneSize,
				right: bboxSceneSize,
				bottom: bboxSceneSize,
			});
		}
	}

	setGraphLines() {
		const { graph, linksCount } = mapStore;
		let lines = new WireCollection(linksCount);
		lines.color = { r: 139 / 0xff, g: 195 / 0xff, b: 195 / 0xff, a: 0.5 };

		let count = 0;
		graph.forEachLink(function(link) {
			let from = graph.getNode(link.fromId).data;
			let to = graph.getNode(link.toId).data;
			lines.add({ from, to });
		});

		this.state.scene.appendChild(lines);
	}

	getTransformCenter(transform) {
		const { screen, innerWidth, navigator } = window;
		let { x, y } = transform;
		if (innerWidth > 768) {
			if (navigator.appVersion.indexOf("Mac") != -1) {
				y = -(screen.availHeight / 2) + screen.height / 4; // ?
			} else {
				y = -(screen.availHeight / 2);
			}
		} else {
			y = screen.availHeight / 2;
		}
		return { x, y };
	}

	moveSceneToCenter() {
		if (this.state.scene) {
			const camera = this.state.scene.getCamera();
			const transform = camera.getTransform();
			const { x, y } = this.getTransformCenter(transform);

			camera.moveTo(x, y);
		}
	}

	ensurePreviousSceneDestroyed() {
		if (this.state.scene) {
			this.state.scene.dispose();
			this.setState({ scene: null });
		}
		// if (this.unsubscribeMoveEvents) {
		// 	this.unsubscribeMoveEvents();
		// 	this.unsubscribeMoveEvents = null;
		// }
	}

	render() {
		const { mapHoverEffect } = mapStore;
		const className = classnames({
			"application__map--canvas": true,
			"application__map--canvas-hover": mapHoverEffect,
		});

		return (
			<div className="application__map--wrapper">
				<div className="application__map">
					<canvas ref={this.canvasRef} className={className}></canvas>
					<SVGScene ref={this.svgSceneRef} cityId={this.props.cityId} canvas={this.canvasRef} scene={this.state.scene} />
				</div>
				<Weather />
				<ZoomMapBar canvas={this.canvasRef} scene={this.state.scene} />
				<MapControl />
				<AppMobilePromt />
			</div>
		);
	}
}
