import { observer } from "mobx-react";
import React from "react";

import mapStore from "../../../data/map.store";

import "./map-control.scss";
@observer
export default class MapControl extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onHandlerShowHatches({ target }) {
		const value = target.type === "checkbox" ? target.checked : target.value;
		mapStore.showHatchesOnMap = value;
	}

	render() {
		const { showHatchesOnMap } = mapStore;
		return (
			<div className="map-control__wrapper">
				<div className="map-control__container">
					<h4>Отображать на карте</h4>
					<ul className="map-control__list">
						<li className="map-control__list--item">
							<span className="map-control__list--item-title">Канализационно-Насосные станции (КНС)</span>
							<label className="map-control__list--checkbox">
								<span className="map-control__list--checkbox--input">
									<input type="checkbox" name="checkbox" checked={showHatchesOnMap} onChange={this.onHandlerShowHatches} />
									<span className="map-control__list--checkbox--control">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
											<path fill="none" stroke="currentColor" strokeWidth="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
										</svg>
									</span>
								</span>
							</label>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}
