import httpService from "./http";

const getMapInfo = async (id) => {
	const data = {
		action: `city/${id}/map`,
		data: {},
	};
	const response = await httpService.post("/", data);

	return response.data; // always return first item
};

const getMapFileBin = async (uri) => {
	const response = await httpService.get(uri, { responseType: "arraybuffer" });
	if (response.status === 200) {
		const points = new Int32Array(response.data);
		return points;
	}
};

const getMapPoints = async (id) => {
	try {
		const data = {
			action: `city/${id}/map/points`,
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data; // always return first item
	} catch (error) {
		return { error };
	}
};

const getMapMeta = async (id, name) => {
	try {
		const data = {
			action: `city/${id}/map/meta`,
			data: {
				name,
			},
		};
		const response = await httpService.post("/", data);

		return response.data; // always return first item
	} catch (error) {
		return { error };
	}
};

export default {
	getMapInfo,
	getMapFileBin,
	getMapPoints,
	getMapMeta
};
