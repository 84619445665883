import { find, filter } from "lodash";
import { extendObservable, computed, action, toJS } from "mobx";
import mouseHandlers from "../helpers/graphMap/mouseHandlers";

class MapStore {
	constructor() {
		extendObservable(this, {
			graph: null,
			graphBBox: null,
			bboxCoords: null,
			originalPoints: [],
			mapPoints: [],

			currentPointId: null,
			currentHoverPointId: null,

			forcedOpeningPointsIds: [],

			mapHoverEffect: false,
			mapChimneySize: null,
			mapHatchSize: null,

			showHatchesOnMap: true,
		});
	}

	@computed
	get bboxCx() {
		if (this.graphBBox) {
			return this.graphBBox.cx;
		} else {
			return null;
		}
	}

	@computed
	get bboxCy() {
		if (this.graphBBox) {
			return this.graphBBox.cy;
		} else {
			return null;
		}
	}

	@computed
	get bboxSceneSize() {
		if (this.graphBBox) {
			return this.graphBBox.width / 8;
		} else {
			return null;
		}
	}

	@computed
	get linksCount() {
		if (this.graph) {
			return this.graph.getLinksCount();
		} else {
			return null;
		}
	}

	@computed
	get mapCx() {
		if (this.bboxCoords) {
			return this.bboxCoords.lat;
		} else {
			return null;
		}
	}

	@computed
	get mapCy() {
		if (this.bboxCoords) {
			return this.bboxCoords.lon;
		} else {
			return null;
		}
	}

	@computed
	get chimneySize() {
		if (this.mapChimneySize) {
			return toJS(this.mapChimneySize);
		} else {
			return null;
		}
	}

	@computed
	get hatchSize() {
		if (this.mapHatchSize) {
			return toJS(this.mapHatchSize);
		} else {
			return null;
		}
	}

	@action
	pushPoint(type, x, y, itemId, r = 12.5, visible = true, hover = false) {
		let id = `${type}-${itemId}`;
		this.mapPoints.push({
			x,
			y,
			r,
			id,
			type,
			visible,
			hover,
		});
	}

	@action
	clonePointsToOriginal() {
		this.originalPoints = this.mapPoints;
	}

	@computed
	get points() {
		return toJS(this.mapPoints);
	}

	@action
	hasPoints() {
		return this.points && this.points.length > 0;
	}

	@computed
	get factories() {
		return filter(this.points, ["type", "factory"]);
	}

	@computed
	get hatches() {
		if (this.showHatchesOnMap) {
			return filter(this.points, ["type", "hatch"]);
		} else {
			return [];
		}
	}

	@computed
	get original() {
		return toJS(this.originalPoints);
	}

	@computed
	get selectedPoint() {
		const { currentPointId } = this;
		if (currentPointId && typeof currentPointId === "string" && currentPointId !== "") {
			let result = this.currentPointId.split("-");
			return {
				type: result[0],
				id: result[1],
			};
		} else {
			return null;
		}
	}

	@action
	findPointIntersects(sceneX, sceneY, scale) {
		if (this.points.length > 0) {
			// if not display hatches.
			const pointsList = this.showHatchesOnMap ? this.points : this.factories;

			for (let itemKey in pointsList) {
				let point = pointsList[itemKey];
				if (point.visible === true) {
					if (this.pointIntersects(sceneX, sceneY, scale, point)) {
						return point;
					}
				}
			}
		}
		return null;
	}

	@action
	pointIntersects(sceneX, sceneY, scale, point) {
		const { x, y, r } = point;
		let dr = this.getPointDr(r, scale);
		let dx = sceneX - x;
		let dy = sceneY - y;
		// console.log("radius: ", r, scale, dr);
		// console.log("pointIntersects", dxx + dyy, dr * dr, dxx + dyy <= dr * dr);
		return dx * dx + dy * dy <= dr * dr;
	}

	/**
	 * Method should be replaced and refactor.
	 */
	@action
	getPointDr(r, scale) {
		let constant = 1; // for mobile devices.
		if (!mouseHandlers.isSmallDevice()) {
			let tempScale = this.roundedScale(scale);
			if (tempScale <= 0.045) {
				constant = r;
			} else if (tempScale >= 0.045 && tempScale <= 0.07) {
				constant = 20;
			} else if (tempScale >= 0.07 && tempScale <= 0.1) {
				constant = 30;
			} else if (tempScale >= 0.1) {
				constant = 50;
			}
		} else {
			constant = 14;
		}

		return constant / scale;
	}

	@action
	roundedScale(number) {
		return +number.toFixed(3);
	}

	@action
	findPointAndSelect(ids) {
		if (this.points.length > 0) {
			let updatedPoints = this.points.map((point) => {
				if (ids.includes(point.id)) {
					point.visible = true;
				} else {
					point.visible = false;
				}

				return point;
			});
			this.mapPoints = updatedPoints;
		}
	}

	@action
	showOnlyPointByIds(ids) {
		if (this.original.length > 0) {
			let updatedPoints = this.original.map((point) => {
				if (point.type === "factory") {
					if (!ids.includes(point.id)) {
						point.visible = false;
					}
				} else if (point.type === "hatch") {
					if (ids.includes(point.id)) {
						if (this.showHatchesOnMap === false) {
							this.showHatchesOnMap = true;
						}
						point.visible = true;
					}
				}
				return point;
			});

			this.mapPoints = updatedPoints;
		}
	}

	@action
	showAllPoints(except = []) {
		let exceptIds = [];
		if (except != []) {
			exceptIds = except.map((item) => item.id);
		}
		if (this.points.length > 0) {
			let updatedPoints = this.points.map((point) => {
				if (!exceptIds.includes(point.id)) {
					point.visible = true;
				}
				return point;
			});
			this.mapPoints = updatedPoints;
		}
	}

	@action
	toggleVisible(pointId, visibleState) {
		let item = find(this.points, ["id", pointId]);
		item.visible = visibleState;
	}

	@action
	toggleHover(pointId) {
		this.currentHoverPointId = pointId;
	}

	@action
	resetHover() {
		this.mapHoverEffect = false;
		this.currentHoverPointId = null;
	}

	@action
	resetCurrentPoint() {
		this.currentHoverPointId = null;
		this.currentPointId = null;
	}
}

export default new MapStore();
