import React from "react";
import ReactDOM from "react-dom";
import Index from "./scenes/Index/Index";

// Created By.
console.info("CREATED BY Vlad Lesovskiy ( inbox@lesovskiy.ru, https://lesovskiy.ru ) and Mirambek Shoykin ( bamerashoykin@gmail.com )");

ReactDOM.render(
	// <React.StrictMode>
		<Index />,
	// </React.StrictMode>,
	document.getElementById("root")
);
