import engineStore from "../../data/engine.store";
import mapStore from "../../data/map.store";
let prevEvent = {
	x: null,
	y: null,
	time: null,
};

const isClickOnHeader = ({ clientY }, isSmallDevice = false) => {
	if (isSmallDevice) {
		return clientY <= 150;
	} else {
		return clientY <= 130;
	}
};

// for mobile
const isClickOnZoomBar = ({ clientX, clientY }) => {
	const zoomBarHeight = 210;
	const zoomBarWidth = 60;
	const { innerWidth, innerHeight } = window;
	if (clientX >= innerWidth - zoomBarWidth && clientY >= innerHeight - zoomBarHeight) {
		return true;
	} else {
		return false;
	}
};

// check if page or datepicker is openned
const isHasOpen = () => {
	const { showAboutUs, showDatePicker, showSearchBarMenu } = engineStore;
	return showAboutUs || showDatePicker || showSearchBarMenu;
};

// check if sidebar is open and client click inside sidebar. !should be factory
const isClickOnSideBar = ({ clientX }) => {
	const { showSidebar } = engineStore;
	const sidebarElement = document.getElementsByClassName("factory-info-sidebar");

	if (sidebarElement) {
		const sidebarElementBounding = sidebarElement[0].getBoundingClientRect();
		if (showSidebar && clientX <= sidebarElementBounding.width) {
			return true;
		}
	}
	return false;
};

const isClickOnSidebarOnSmallDevice = () => {
	const { showSidebar } = engineStore;
	return showSidebar;
};

const isSmallDevice = () => {
	const { innerWidth } = window;
	if (innerWidth <= 768) {
		return true;
	} else {
		return false;
	}
};

const clearPrevEvent = () => {
	prevEvent.x = null;
	prevEvent.y = null;
	prevEvent.time = null;
};

const savePrevEvent = (e) => {
	const touch = getMainTouch(e);

	prevEvent.x = touch.clientX;
	prevEvent.y = touch.clientY;
	prevEvent.time = Date.now();
};

const getMainTouch = (e) => {
	if (e instanceof TouchEvent) {
		return (e.changedTouches || e.touches)[0];
	} else {
		return e;
	}
};

const getCoordPipeError = ({ clientX, clientY }, pixelRatio) => {
	const { chimneySize } = mapStore;
	let x = chimneySize ? clientX - (chimneySize.width * pixelRatio) / 2 : clientX;
	let y = chimneySize ? clientY - (chimneySize.height * pixelRatio) / 2 : clientY;
	return { x, y };
};

const checkApplicabilityForEvent = (e) => {
	if (isSmallDevice()) {
		let mainTouch = getMainTouch(e);
		if (isHasOpen()) {
			return false;
		}
		if (isClickOnHeader(mainTouch, true) || isClickOnZoomBar(mainTouch) || isClickOnSidebarOnSmallDevice()) {
			return false;
		} else {
			return true;
		}
	} else {
		if (isHasOpen()) {
			return false;
		}
		if (isClickOnHeader(e) || isClickOnSideBar(e) || isClickOnZoomBar(e)) {
			return false;
		} else {
			return true;
		}
	}
};

const isDoubleClick = (e) => {
	if (prevEvent.x === null && prevEvent.y === null) {
		savePrevEvent(e);
		return false;
	} else {
		const touch = getMainTouch(e);
		const timeDiff = Date.now() - prevEvent.time;
		const isSamePlace = Math.hypot(touch.clientX - prevEvent.x, touch.clientY - prevEvent.y) < 100;

		clearPrevEvent();

		if (timeDiff < 2000 && isSamePlace) {
			return true;
		} else {
			return false;
		}
	}
};

const isTouchEvent = (e) => {
	if ("mozInputSource" in e) {
		var source = e.mozInputSource;
		if (source == MouseEvent.MOZ_SOURCE_PEN || source == MouseEvent.MOZ_SOURCE_TOUCH) {
			return true;
		} else {
			return false;
		}
	} else {
		return e instanceof TouchEvent;
	}
};

export default { checkApplicabilityForEvent, isDoubleClick, isSmallDevice, getCoordPipeError, isTouchEvent };
