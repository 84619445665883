import api from "./api/city.api";
import cityStore from "../data/city.store";
import { isEmpty, find } from "lodash";
import engineStore from "../data/engine.store";

const initAvailableCities = async () => {
	const result = await api.getAvailable();

	if (result && result.length > 0) {
		cityStore.setAvailableCities(result);
	}
	engineStore.toggleLoader(false);
};

const initCity = async (city) => {
	const { availableCities } = cityStore;
	if (isEmpty(availableCities)) {
		const result = await api.getCityByName(city);
		if (result) {
			cityStore.initCity(result);
		}
	} else {
		const cityItem = find(availableCities, ["slug", city]);
		cityStore.initCity(cityItem);
	}
};

export default { initAvailableCities, initCity };
