import React from "react";
import NotFoundCity from "../../components/not-found-city/NotFoundCity";
import cityService  from "../../services/city.service";

import "./not-found-city.scss";

export default class NotFoundCityPage extends React.Component {
	async componentDidMount() {
		await cityService.initAvailableCities();
	}

	render() {
		return (
			<div className="not-found-city-page__container">
				<NotFoundCity></NotFoundCity>
			</div>
		);
	}
}
