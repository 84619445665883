import React from "react";
import { observer } from "mobx-react";
import cityStore from "../../data/city.store";

import { Link } from "react-router-dom";

import "./not-found-city.scss";

@observer
export default class NotFoundCity extends React.Component {
	renderList() {
		const { availableCities } = cityStore;
		if (availableCities && availableCities.length > 0) {
			let items = availableCities.map((item) => {
				return (
					<li key={item.slug}>
						<Link replace to={`/${item.slug}`}>
							{item.name}
						</Link>
					</li>
				);
			});
			return <ul className="not-found-city__citylist">{items}</ul>;
		} else {
			return null;
		}
	}

	render() {
		return (
			<div className="not-found-city">
				<div className="not-found-city__container">
					<h2>К сожалению у нас нет в базе таких городов, попробуйте доступные</h2>
					{this.renderList()}
				</div>
			</div>
		);
	}
}
