import httpService from "./http";
import CityStore from "../../data/city.store";

const dateToFormat = (date) => {
	return `${date.getDate()}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
};

const getCurrentWind = async () => {
	try {
		const { cityId } = CityStore;
		const data = {
			action: `weather/city/${cityId}/wind`,
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data; // always return first item
	} catch (error) {
		return { error };
	}
};

const getWindByDate = async (date) => {
	try {
		const { cityId } = CityStore;
		const formated = dateToFormat(date);
		const data = {
			action: `weather/city/${cityId}/wind/date`,
			data: {
				date: formated,
			},
		};
		const response = await httpService.post("/", data);

		return response.data; // always return first item
	} catch (error) {
		return { error };
	}
};

export default {
	getCurrentWind,
	getWindByDate,
};
