import httpService from "./http";

const getCityByName = async (name) => {
	try {
		const data = {
			action: "city",
			data: {
				name: name,
			},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

const getAvailable = async () => {
	try {
		const data = {
			action: "city/available",
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

export default { getAvailable, getCityByName };
