import React from "react";

import classnames from "classnames";
import { observer } from "mobx-react";

import entityService from "../../services/entity.service";

import "./factory-info-sidebar.scss";
import mapStore from "../../data/map.store";
import bus from "../../helpers/graphMap/bus";
import { isEmpty, result } from "lodash";
import ComponentLoader from "../component-loader/ComponentLoader";
import engineStore from "../../data/engine.store";

@observer
export default class FactoryInfoSidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			hasError: false,
			item: {},
		};
	}

	componentDidMount() {
		bus.on("select-factory", this.getItem, this);
	}

	async getItem() {
		const { selectedPoint } = mapStore;
		engineStore.toggleSidebar(true);
		if (selectedPoint !== null) {
			this.setState({ loading: true });

			mapStore.forcedOpeningPointsIds = []; // ?

			const result = await entityService.get(selectedPoint);
			if (result) {
				this.setState({ loading: false, item: result });
				this.selectedPointAction(result);
			} else {
				this.setState({ loading: false, hasError: true });
				engineStore.toggleSidebar(false);
			}
		} else {
			engineStore.toggleSidebar(false);
		}
	}

	async selectedPointAction({ id, type }) {
		if (type === "hatch") {
			const resultRelatedHatchesIds = await entityService.getRelatedHatces(id);
			if (resultRelatedHatchesIds && Array.isArray(resultRelatedHatchesIds)) {
				mapStore.forcedOpeningPointsIds = resultRelatedHatchesIds;
			}
		}
	}

	renderSubstances(substances) {
		if (!isEmpty(substances)) {
			let blockItems = substances.map((item, key) => {
				if (item.name != "" || item.values) {
					return (
						<li key={key} className="factory-info__substances--item">
							<h3>{item.name}</h3>
							<div className="factory-info__substances--item-values">
								<span className="values-mass">{item.values.mass}</span>
								<span className="values-power">{item.values.power}</span>
							</div>
						</li>
					);
				}
			});
			return <ul className="factory-info__substances--list">{blockItems}</ul>;
		} else {
			return null;
		}
	}

	getFactoryImage(src) {
		if (src && src != "") {
			return src;
		} else {
			const { item } = this.state;
			if (item && item.type) {
				return require(`./images/${item.type}.png`);
			} else {
				return require("./images/thumbnail.png");
			}
		}
	}

	renderAdditionalInfo() {
		const { item } = this.state;

		if (item && item.type === "hatch") {
			return (
				<div className="factory-info__content--additional">
					<p>Совокупные испарения со всех люков города</p>
				</div>
			);
		}
	}

	renderBlock() {
		const { item, loading } = this.state;
		if (!isEmpty(item) && loading === false) {
			const { name, subName, company, address, thumbnail, substances } = item;

			const substanceRendered = this.renderSubstances(substances);
			return (
				<div className="factory-info__content">
					<div className="factory-info__content--header">
						<div className="factory-info__content--header-img">
							<img src={this.getFactoryImage(thumbnail)} alt="" />
						</div>
						<div className="factory-info__content--header-wrapper">
							{company !== undefined && <h2 className="factory-info__content--title">{company}</h2>}
							<h2 className="factory-info__content--title">
								{name}
								<span className="small">{subName}</span>
							</h2>
							<h4 className="factory-info__content--address">{address}</h4>
						</div>
					</div>
					{this.renderAdditionalInfo()}
					<div className="factory-info__content--substances-header">
						<div className="title-wrapper">
							<span className="small-title">Масса</span>
							<span className="small-subtitle">(тонн в год)</span>
						</div>
						<div className="title-wrapper">
							<span className="small-title">Мощность</span>
							<span className="small-subtitle">(грамм в секунду)</span>
						</div>
					</div>
					<div className="factory-info__content--substances">
						<div className="factory-info__content--substances-wrapper">{substanceRendered}</div>
					</div>
				</div>
			);
		} else {
			return <ComponentLoader />;
		}
	}

	closeHandler() {
		const { item } = this.state;
		if (item.type === "hatch") {
			mapStore.showAllPoints();
			mapStore.forcedOpeningPointsIds = [];
		}

		mapStore.resetCurrentPoint();
		engineStore.toggleSidebar(false);
	}

	render() {
		const { showSidebar } = engineStore;

		const className = classnames({
			"factory-info-sidebar": true,
			"show-from-left": showSidebar,
		});

		return (
			<div className={className}>
				<div className="factory-info-sidebar--container">
					<div className="factory-info-sidebar--control">
						<div className="control-close" onClick={() => this.closeHandler()}>
							<span></span>
							<span></span>
						</div>
					</div>
					{this.renderBlock()}
				</div>
			</div>
		);
	}
}
