import React from "react";
import { string } from "prop-types";

import "./content-parts.scss";

export default class SimpleContentParts extends React.Component {
	static propTypes = {
		title: string,
		content: string,
	};

	static defaultProps = {
		title: "",
		content: "",
	};

	render() {
		const { title, content } = this.props;
		return (
			<div className="simplecontent-page__content--container">
				<h1 className="simplecontent-page__content--title">{title}</h1>
				<div className="simplecontent-page__content--content" dangerouslySetInnerHTML={{__html: content}}></div>
			</div>
		);
	}
}
