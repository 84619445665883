import httpService from "./http";

const getById = async (id) => {
	try {
		const data = {
			action: `hatch/${id}`,
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

const getRelated = async (id) => {
	try {
		const data = {
			action: `hatch/${id}/related`,
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data;
	} catch (error) {
		return { error };
	}
};

const getByCompany = async (label, cityId) => {
	const data = {
		action: `hatch/related/company`,
		data: {
			cityId,
			name: label,
		},
	};
	const response = await httpService.post("/", data);

	return response.data;
};

export default { getById, getRelated, getByCompany };
