import { extendObservable, computed, action, toJS } from "mobx";

class CityStore {
	constructor() {
		extendObservable(this, {
			currentCity: null,
			cities: [],
		});
	}

	@action
	initCity(cityObject) {
		this.currentCity = cityObject;
	}

	@computed
	get city() {
		return toJS(this.currentCity);
	}
	@computed
	get cityId() {
		if (this.city) {
			const { id } = this.city;
			return id;
		} else {
			return 0;
		}
	}

	@computed
	get timezone() {
		if (this.city) {
			const { timezone } = this.city;
			return timezone;
		} else {
			return null;
		}
	}

	@action
	setAvailableCities(value) {
		this.cities = value;
	}

	@computed
	get availableCities() {
		return toJS(this.cities);
	}
}

export default new CityStore();
