import httpService from "./http";

const getContents = async () => {
	try {
		const data = {
			action: "contents",
			data: {},
		};
		const response = await httpService.post("/", data);

		return response.data; // always return first item
	} catch (error) {
		return { error };
	}
};

export default {
	getContents,
};
