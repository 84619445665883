import React from "react";
import { DateTime } from "luxon";

import DatePickerPopup from "./date-picker-popup/DatePickerPopup";
import WeatherApi from "../../services/api/weather.api";

import engineStore from "../../data/engine.store";
import cityStore from "../../data/city.store";

import "./weather.scss";

export default class Weather extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			windValue: 3,
			windRotate: 0,
			time: null,
			showDate: null,
			timezone: null,
			showDatePopup: false,
		};

		this.dateChangeHandler = this.dateChangeHandler.bind(this);
		this.closeDatePopup = this.closeDatePopup.bind(this);
	}

	componentDidMount() {
		const { timezone } = cityStore;
		this.setState({ time: DateTime.local().setZone(timezone) });
		this.update = setInterval(() => {
			this.setState({ time: DateTime.local().setZone(timezone) });
		}, 60 * 1000);

		this.getCurrentWind();
	}

	componentWillUnmount() {
		clearInterval(this.update);
	}

	async getCurrentWind() {
		const result = await WeatherApi.getCurrentWind();
		console.log("getCurrentWind", result);
		if (result) {
			this.setState({
				windValue: result.speed,
				windRotate: result.deg,
			});
		}
	}

	dateChangeHandler(date, getWind = true) {
		console.log("dateChangeHandler", date, getWind);
		this.setState({ showDate: date }, () => {
			if (getWind) {
				this.getWindByDate(date);
			}
		});
	}

	async getWindByDate(date) {
		const result = await WeatherApi.getWindByDate(date);
		console.log("getWindByDate", result);
		if (result) {
			this.setState({
				windValue: result.speed,
				windRotate: result.deg,
			});
		}
	}

	showDatePopup() {
		this.setState({ showDatePopup: true }, () => {
			engineStore.toggleDatePicker(true);
		});
	}

	closeDatePopup(wasClosed) {
		this.setState({ showDatePopup: false }, () => {
			engineStore.toggleDatePicker(false);
			if (wasClosed) {
				this.getCurrentWind();
			}
		});
	}

	getTime(format) {
		const { time } = this.state;
		if (time) {
			return time.toFormat(format);
		} else {
			return null;
		}
	}

	getDate() {
		const { showDate } = this.state;
		if (showDate) {
			return `${showDate.getDate()}.${("0" + (showDate.getMonth() + 1)).slice(-2)}.${showDate.getFullYear()}`;
		} else {
			return null;
		}
	}

	render() {
		const { windValue, windRotate, showDatePopup } = this.state;
		const arrowStyle = {
			transform: `rotate(${windRotate}deg)`,
		};
		return (
			<div>
				<div className="weather-component">
					<div className="weather__container">
						<div className="weather__container--union">
							<span className="union-icon" onClick={() => this.showDatePopup()}></span>
						</div>
						<div className="weather__container--wind">
							<div className="wind-arrow" style={arrowStyle}></div>
							<div className="weather__content--wind-wrapper">
								<span className="weather__content--wind-title">Ветер</span>
								<span className="weather__content--wind-value">{windValue} М/С</span>
							</div>
						</div>
					</div>
					<div className="weather__date">
						<div className="weather__date--time">
							<span className="weather__date--time-hours">{this.getTime("HH")}:</span>
							<span className="weather__date--time-minutes">{this.getTime("mm")}</span>
						</div>
						<div className="weather__date--date">
							<span>{this.getDate()}</span>
						</div>
					</div>
				</div>
				<DatePickerPopup show={showDatePopup} dateHandler={this.dateChangeHandler} closeHandler={this.closeDatePopup} />
			</div>
		);
	}
}
